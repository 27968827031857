<template>
  <div id="app">
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
      <div class="container-fluid">
        <a class="navbar-brand" href="#" @click="tab='home'">慧心独具</a>
        <ul class="nav nav-pills">
          <li class="nav-item" @click="tab='introduction'">
            <button class="nav-link">
              简介
            </button>
          </li>
<!--          <li class="nav-item dropdown">-->
<!--            <a class="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown">-->
<!--              产品-->
<!--            </a>-->
<!--            <ul class="dropdown-menu dropdown-menu" aria-labelledby="navbarDarkDropdownMenuLink">-->
<!--              <li><a class="dropdown-item" href="#" @click="tab='fupanbao'">复盘宝</a></li>-->
<!--              <li><a class="dropdown-item" href="#"  @click="tab='xwxn'">信我信你</a></li>-->
<!--            </ul>-->
<!--          </li>-->
          <li class="nav-item" @click="tab='fupanbao'">
            <button class="nav-link">
              复盘宝
            </button>
          </li>
          <li class="nav-item" @click="tab='xwxn'">
            <button class="nav-link">
              信我信你
            </button>
          </li>
          <li class="nav-item" @click="tab='contact'">
            <button class="nav-link">
              联系我们
            </button>
          </li>
        </ul>
      </div>
    </nav>
    <div>
      <div v-if="tab==='home'" class="d-flex flex-column justify-content-center align-items-center" style="height: 80vh">
        <div class="display-1 m-3">数据洞察价值</div>
        <div class="display-6 m-3">
          专业的数据分析平台
        </div>
      </div>
      <div v-if="tab==='introduction'" class="d-flex flex-column justify-content-center align-items-center" style="height: 80vh">
        <div class="h1 m-3">
          慧心独具智能科技是专业的数据分析平台提供商
        </div>
        <div class="h1 m-3">
          坚信数据是最重要的资产
        </div>
        <div class="h1 m-3">
          我们致力于帮助客户从数据中洞察价值
        </div>
        <div class="h1 m-3">
          发现新的机遇
        </div>
        <div class="h1 m-3">
          做出更好的决策
        </div>
      </div>
      <div v-if="tab==='fupanbao'" class="d-flex flex-column justify-content-center align-items-center">
        <div class="display-1">复盘宝</div>
        <div class="h4 m-3" style="line-height:200%;">
          股票复盘，
          模拟炒股，<br>
          回到历史任意时刻，<br>
          选择多种时间流速重现股市行情变化，<br>
          提供财务数据的各种可视化分析，<br>
          支持Android、iOS、Web、小程序等多种平台
        </div>
        <button class="display-6 btn btn-primary m-3" onclick="location.href='https://fupanbao.sophypro.com'">
          查看详情
        </button>
        <div class="d-flex">
          <div class="m-3">
            <div>
              <a href="https://sophypro-public-production.oss-cn-hangzhou.aliyuncs.com/fupanbao_latest.apk">
                Android:
              </a>
            </div>
            <img src="fupanbao_android.png"/>
          </div>
          <div class="m-3">
            <div>微信小程序: </div>
            <img src="fupanbao_wx.jpg"/>
          </div>
        </div>
      </div>
      <div v-if="tab==='fresh'" class="d-flex flex-column justify-content-center align-items-center">
        <div class="display-1">有所值</div>
        <div class="h4 m-3" style="line-height:200%;">
          零门槛，
          操作简单，<br>
          产品预购，
          配送到家，<br>
          一键帮助商家解锁两大能力，<br>
          支持Android、iOS、Web、小程序等多种平台
        </div>
        <button class="display-6 btn btn-primary m-3" onclick="location.href='https://ysz.sophypro.com'">
          查看详情
        </button>
        <div class="h1">
          审核中，web、app暂不可用，敬请期待
        </div>
        <div class="d-flex">
          <div class="m-3">
            <div>微信小程序: </div>
            <img src="fresh_wx.jpg"/>
          </div>
        </div>
      </div>
      <div v-if="tab==='xwxn'" class="d-flex flex-column justify-content-center align-items-center">
        <div class="display-1">信我信你</div>
        <div class="h4 m-3" style="line-height:200%;">
          生鲜全产业链信息系统，<br>
          让信任贯穿整个产业链<br>
        </div>
        <button class="display-6 btn btn-primary m-3"
                onclick="location.href='https://fresh.sophypro.com'">
          查看详情
        </button>
        <div class="d-flex flex-row flex-wrap">
          <div class="mx-1">
            <img width="200" height="200" src="xwxn_latest_qr.png"/>
            <div>
              <button type="button" class="btn btn-outline-success btn-lg m-3"
                       onclick="location.href='https://sophypro-public-production.oss-cn-hangzhou.aliyuncs.com/xwxn_latest.apk'">
                下载Android
              </button>
            </div>
          </div>
          <div class="d-flex flex-column mx-1">
            <img width="200" height="200" src="xwxn_xcx.jpg"/>
            <div type="button" class="btn btn-outline-success disabled btn-lg m-3">
              微信小程序
            </div>
          </div>
        </div>
      </div>
      <div v-if="tab==='contact'" class="d-flex flex-column justify-content-center align-items-center" style="height: 80vh">
        <div class="h1">
          地址：杭州市西湖区文三路259号
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Dropdown } from 'bootstrap'

export default {
  name: 'App',
  components: {

  },
  data() {
    return {
      tab: 'home'
    }
  },
  onLoad(option) {
    try {
      console.log(option)
      if (Object.prototype.hasOwnProperty.call(option, 'tab')) {
        this.tab = option['tab']
      }
    } catch (e) {
      console.log(e)
    }
  },
  mounted() {
    // let triggerTabList = [].slice.call(document.querySelectorAll('#myTab a'))
    // triggerTabList.forEach(function (triggerEl) {
    //   let tabTrigger = new Tab(triggerEl)
    //
    //   triggerEl.addEventListener('click', function (event) {
    //     event.preventDefault()
    //     tabTrigger.show()
    //   })
    // })
    let dropdownElementList = [].slice.call(document.querySelectorAll('.dropdown-toggle'))
    dropdownElementList.map(function (dropdownToggleEl) {
      return new Dropdown(dropdownToggleEl)
    })

    const v = this.getQueryString('tab')
    if (v && v !== '') {
      this.tab = v
    }
  },
  methods: {
    changeTab(tag) {
      this.tab = tag
    },
    getQueryString(name) {
      let reg = `(^|&)${name}=([^&]*)(&|$)`
      let r = window.location.search.substr(1).match(reg);
      if (r != null) return unescape(r[2]); return null;
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
